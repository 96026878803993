import { createAction, props } from '@ngrx/store';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';

export const MyHarviaActions = {
  getProducts: createAction(GET_PRODUCTS, (page?: number, size?: number, payload?: any) => ({
    ...payload,
    page,
    size,
  })),
  getDataSuccess: createAction(GET_PRODUCTS_SUCCESS, props<any>()),
};
export default MyHarviaActions;
